<template>
  <div class="paddme">
    <h1 class="main_title">
      <div v-text="'היי ' + get_me().display_name"></div>
    </h1>
    <div style="font-weight:100;" >{{get_word('hello_text')}}</div>
  </div>
  <div id="prev_orders" class="box" v-if="loaded && orders.length > 0" style="margin-bottom: 20px;">
    <div class="line"> 
        <i class="fas fa-swimming-pool"></i>
        <div class="content">
          <h2>{{get_word('my_orders')}} </h2>
          <ul>
            <li v-for="item in orders" :key="item.id">
              <div class="ord_wrapper">
                <div class="text">
                  <div class="inner">
                    <div> {{item.mikve_data.city}} - {{item.mikve_data.address}}</div>
                    <span>{{item.apt_time}} </span>
                    <div v-if="item.pack && item.pack != '' && item.pack.name != '' "><b>{{item.pack.name}}: </b> {{ item.pack.uses }}/{{ item.pack.num_apt }} </div>
                     <div v-else>
                        <b v-if="item.type_id == 1">טבילה רגילה</b>
                        <b v-if="item.type_id == 2">טבילת הכנה</b>
                        <b v-if="item.type_id == 3">טבילת כלה ראשונה</b>
                     </div>
                     <div v-if="item.mikve_data.is_apt != '1'"><b>סטטוס תשלום:</b> {{ (item.is_paid == 1 ? 'שולם' : 'לא שולם')}}</div>
                     <div v-if="item.mikve_data && item.mikve_data.workers && item.mikve_data.workers.length > 0">
                       <b> בלנית עובדת: </b>
                        <span v-for="(work,i) in item.mikve_data.workers" :key="i"> {{ work }} </span>
                     </div>
                  </div>
                  <div><router-link :to="'/edit/' + item.id" class="btn_white btn small_btn">{{get_word('edit_deep')}} </router-link></div>
                </div>   
                <div class="links">
                  <div class="order_wrapper_title">לניווט</div>
                    <div>
                        <a target="_blank" class="btn_white" :href="item.mikve_data.waze_link"><i class="fab fa-waze"></i> </a>
                    </div>
                    <div>
                        <a target="_blank" class="btn_white" :href="item.mikve_data.google_maps_link"><i class="fas fa-map-marker-alt"></i> </a>
                    </div>
                </div>
                <div v-if="item.orders && item.orders.length > 0">
                  <div class="order_wrapper_title">רכישות נוספות</div>
                  <ul class="order_wrapper" v-for="(ord,i) in item.orders" :key="i">
                    <template v-if="ord.prods && ord.prods.length > 0">
                      <li class="prod_wrapper" v-for="(prod,z) in ord.prods" :key="z">
                      {{prod.qty}}  {{prod.name}}  
                      </li>
                    </template>
                  </ul>
                </div>
                <div class="btn_wrapper">
                  <router-link v-if="item.orders && !item.orders.length > 0 && item.mikve_data.is_apt == '0'" :to="'/shop/' + item.id" class="btn">רכישת מוצרים נוספים</router-link>
                    <a v-if="item.is_paid == 0 || item.mikve_data.is_apt == '1' " @click="cancelApt(item.id)"  class="btn red">ביטול טבילה</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
		<payForOrder v-if="isPayOrd" @close="closePopUp()" :paymentData="paymentData" />
   
    <!-- <div v-if="!showNewApt" style="text-align: center;">
      <a class="btn" @click="showNewApt=true; scrollDown()"><i class="fas fa-plus"></i> הזמיני תור נוסף</a>
    </div> -->

    <div id="home" class="box" v-if="loaded && showNewApt" style="margin-bottom: 80px;">
      <div class="line" >
        <i class="fas fa-map-marker-alt"></i>
        <div class="content">
          <div  class="fav_mikve">
            <h2 class="mikve_title" v-html="mikve_title"></h2>
            <div style="display: flex; gap:10px;justify-content: center;">
              <button class="btn_white" v-html="get_word('change_mikve')" @click="isChangeMikve = true; "></button>
            </div>
          </div>
          <mikveList v-if="isChangeMikve" @change="get_prices()" @close="isChangeMikve = false" v-model:mikveId="orderData.selectedMikve" v-model:mikveName="mikveName" />
        </div>
      </div>
      <div class="line">
        <i class="fas fa-table"></i>
        <div class="content">
          <div id="date_pick_wrapper">
            <h2 v-html="get_word('choose_date')"></h2>
            <!-- :max-date="Date.now() + (6.048e+8*max_dates_in_weeks)" -->
             <VueDatePicker  v-model="orderData.selectedDate" auto-apply format="dd/MM/yyyy" @update:model-value="onSelectDate" week-start="0"  :min-date="new Date()"  :day-names="['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז']"  locale="he" cancelText="ביטול" selectText="בחרי" ></VueDatePicker>
          </div>
        </div>
      </div>
      
      <div class="line last">
        <i class="fas fa-scroll"></i>
        <div class="content">

          <h2 v-html="get_word('choose_type')"></h2>
          <ul class="radio_opts">
            <template v-for="otype in order_types"  :key="otype.id">
               <li @click="orderData.data = otype" :class="{active: orderData.data.id == otype.id}">{{otype.name }}<span v-if="!is_apt">{{otype.price}}₪</span></li>
            </template>
          </ul>
          <div class="apt_length" v-if="orderData.data?.is_dynamic_time == 1">
            <span>אורך הטבילה</span>
            <br>
            <input type="radio" id="apt_length15" v-model="apt_length" name="apt_length" value="15">
            <label for="apt_length15">15 דק</label>
            <br>
            <input type="radio" id="apt_length1" v-model="apt_length" name="apt_length" value="30">
            <label for="apt_length1">30 דק</label>
            <br>
            <input type="radio" id="apt_length2" v-model="apt_length" name="apt_length" value="45">
            <label for="apt_length2">45 דק</label> 
            <br>
            <input type="radio" id="apt_length3" v-model="apt_length" name="apt_length" value="60">
            <label for="apt_length3">שעה</label>
        </div>
        </div>
      </div>
      <button class="btn" id="big_btn" @click="go_to_order">הזמנת טבילה</button>

    </div>
     <div id="unpaid" class="box" v-if="loaded && (unpaid_orders && !isEmpty(unpaid_orders)) || (unpaid_shop_orders && !isEmpty(unpaid_shop_orders))" style="margin-bottom: 20px;">
    <div class="line"> 
        <i class="fas fa-hand-holding-usd"></i>
        <div class="content">
          <h2>{{get_word('unpaid_orders')}} </h2>
          <ul v-if="unpaid_orders &&  !isEmpty(unpaid_orders)">
            <li v-for="(item,i) in unpaid_orders" :key="i" >
              <template v-if="item.mikve_data.is_apt == '0'">
                <div class="ord_wrapper">
                  <div class="text">
                    <div>{{get_word('deep')}}</div>
                    <div>{{item.parse_date}} | {{get_word('price')}} - {{item.price}}₪</div>
                  </div>
                  <button @click="pay_for_order(item.id,true)"  class="btn">{{get_word('pay')}} </button>
                </div>
              </template>
            </li>
          </ul>
          <ul v-if="unpaid_shop_orders && !isEmpty(unpaid_shop_orders)">
            <li v-for="(item,i) in unpaid_shop_orders" :key="i" >
              <template v-if="item.mikve_data.is_apt == '0'">
                <div class="ord_wrapper">
                  <div class="text">
                    <div> {{get_word('shop_order')}} </div>
                    <div>{{item.parse_date}} |  {{get_word('price')}}: {{item.price}}₪</div>
                  </div>
                  <button @click="pay_for_order(item.id)"  class="btn">{{get_word('pay')}} </button>
                </div>
                </template>  
            </li>
          </ul>
        </div>
      </div>
    </div>
</template>
  
  <script>
  import mikveList from '@/views/mikveList.vue';
  import payForOrder from "@/views/payForOrder.vue";
  export default {
   
    components:{
      mikveList,
      payForOrder
          },
    
    data() {
  
      return {
        orders: [],
        prices: [],
        order_types: [],
        unpaid_orders: [],
        unpaid_shop_orders: [],
        types: this.$store.order_types,
        orderData: {
          selectedMikve: '',
          data:{},
          selectedDate: new Date().toISOString().slice(0,10),
          orderType: 0
        },
        paymentId:0,
        mikveName:'',
        is_apt:false,
        max_dates_in_weeks:2,
        apt_length:30,
        isPayOrd:false,
        show:true,
        loaded:false,
        isChangeMikve: false,
        paymentData: {},
        selectedMikveDetails: {},
        mikves: [],
        selectedMikveDates: [],
        showNewApt:true
      
      }
    },
    methods: {
     
      closePopUp() {
        this.isPayOrd = false;
        this.updateDate();
      },
      pay_for_order(order_id,shop = false) {
      if(shop !== false)
      {
        this.api({ action: "pay_for_order", data: { order_id: order_id } },(data) => {
            if (data.data) {
              this.paymentData = data.data;
              this.isPayOrd = true;
            }
          }
        );
      }
      else{
        this.api(
				{ action: "pay_for_shop_order", data: { order_id: order_id } },
				(data) => {
					if (data.data) {
						this.paymentData = data.data;
						this.isPayOrd = true;
					}
				}
			);
      }
			
		},
      scrollDown(){
        setTimeout(() => {
          document.getElementById('home').scrollIntoView({ behavior: "smooth" });
        }, 100);
      },
    
      get_prices() {
        this.api({action: 'get_mikve_data',data: { mikve_id: this.orderData.selectedMikve } }, (data)=>{
          this.is_apt = data.data.is_apt;
          this.order_types = data.data.types;
          this.$store.order_types = data.data.types;
          localStorage.setItem('order_types', JSON.stringify(data.data.types));
          this.orderData.data = data.data.types[0];
          this.prices = data.data.prices;
          if(+data.data.max_dates_in_weeks > 0)
          {
              this.max_dates_in_weeks = data.data.max_dates_in_weeks;
          }
          
        });
      },
      cancelApt(id) {
        this.api({action: 'cancel_apt',data: { id: id } }, ()=>{
          this.alert('המידע נשמר בהצלחה!');

            this.$bus.$emit('reload');
        });
      },
      foucs(){
          this.$refs["date_input"].foucs();
      },
      onSelectDate(event){
        console.log(event)
      },
      getDates(){
        this.api({action: 'get_dates' }, (data)=>{
          this.selectedMikveDates = data.data;
          this.show = true;
        });
      },
     
      go_to_order() {
        if(this.orderData.data?.is_dynamic_time == 1)
        {
          this.$router.push(`/mikve/${this.orderData.selectedMikve}/${this.orderData.selectedDate}/${this.orderData.data.id}+${this.apt_length}`)
        }
        else{
            this.$router.push(`/mikve/${this.orderData.selectedMikve}/${this.orderData.selectedDate}/${this.orderData.data.id}`)
        }
        
      }
    },
    mounted() {
      this.orderData.selectedMikve = this.isNull(this.$store.user.mikve) ? '' : this.$store.user.mikve.id;
      this.mikveName = this.isNull(this.$store.user.mikve) ? '' : this.$store.user.mikve.name;
   

      this.api({ action: 'get_orders'}, (data)=>{
        this.orders = data.data.orders;

        this.unpaid_orders = data.data.unpaid_orders;
        this.unpaid_shop_orders = data.data.unpaid_shop_orders;


        if (data.data.orders && data.data.orders.length > 0) this.showNewApt=false;

        this.get_prices();
    
        
      });
      this.loaded = true;

    },
    computed: {
      
        words() {
          return this.$store.state.words
        },
        mikve_title() {
          return this.get_word('mikve_title').replace('mikve', "")+'<div>'+this.mikveName + '</div>';
        },
    },
  }
  </script>
  
<style lang="scss">
  .links{   display: flex;   justify-content: flex-start;   gap: 10px;align-items: center;
        a{text-decoration: none;display: flex;justify-content: center;align-items: center; font-size: 14px !important;   height: auto;    border-color: #127dbb !important;color: #127dbb !important;height: 30px;width: 30px;}
        .svg-inline--fa {color: #127dbb !important;font-size: 16px;}
    }
  .order_wrapper_title{font-weight: 600;}
  .order_wrapper{
    display: flex;justify-content: flex-start;flex-direction: column;    gap: 1px;;padding: 5px 16px 5px 5px;margin-bottom: 10px;
      border-radius: 10px;list-style-type: disc !important;
      li{margin-bottom: 0 !important;padding-bottom: 0 !important;border-bottom: none !important;}
      .is_paid{
        .red{font-weight: 700;color: red;}
        .green{font-weight: 700;color:#58c343;}
      }
    }
  #unpaid{
    &{background:  #ff0000ad;color: #fff;}
    h2,.line i, .line svg{color: #fff;}
    .line{border-bottom:none}
    ul li{border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
    ul li:last-of-type{border-bottom: none;}
    .ord_wrapper{align-items: center;}
    .btn{height: auto;padding: 0 8px;font-size: 16px;color: rgba(255, 0, 0, 1); font-weight: 600;background:#fff;}
  }
#prev_orders {
  .fa-comments{color: #fff;}
  .btn{height: auto !important;padding: 5px 10px;line-height: 1;}
  .line {padding-bottom: 0px; border: none;;
      .ord_wrapper{flex-direction: column;}
      .btn_wrapper{gap: 5px;display: flex;flex: 1;flex-direction: row;
        .btn,.btn_white{font-size: 16px;line-height: 1;    flex: 1;height: auto;}
        .red{color: rgba(255, 0, 0, 1); background: #fff;border: 2px solid  rgba(255, 0, 0, 1);font-weight: 600;}
      }
      .text{flex: 2; display: flex; justify-content: space-between;align-items: center;}
  }
  ul li { border-bottom: 1px solid rgba(0, 0, 0, 0.1); font-size: 16px; padding-bottom: 15px; margin-bottom: 15px;
    &:last-child { border-bottom: none; padding: 0px; margin: 0px;}
  }
}

.apt_length{
  span{font-weight: bold;}
  input{margin-left: 10px;}
}
.main_title { font-size: 24px; }

.mikve_title { margin-bottom: 10px;
  div { font-size: 18px;font-weight: 200;}
}
.ord_wrapper{display: flex;justify-content: space-between;gap: 5px;
  .purchase{    font-weight: 600; font-size: 17px;text-decoration: underline;}
}
.item{padding: 20px 0;}
.content{flex-grow: 1;}
  #home { padding-bottom: 60px;

    #mikve_select_wrapper{
      &{margin: 10px 0;}
    }

    #date_pick_wrapper {
      &{display: flex;align-items: flex-start;flex-direction: column;}
    }

    .select2-container{
      &{width: 100%!important;height: 48px;}
      *{height: 48px;}
    }
  }
  .radio_opts { list-style: none; width: 100%;
    li { display: flex; border-bottom: 1px solid #eee; line-height: 50px; padding-right: 15px;justify-content: space-between;padding: 0 10px;
      &.active { background: var(--color_dom); color: #fff;}
    }

  }
  .small_btn{padding: 5px !important;font-size: 16px !important;}
</style>