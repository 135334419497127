<template>
  <div id="selected_day_page" v-if="loaded">
    <h1 v-html="title"></h1>
    <h2>{{get_word('available_hours')}} {{date}}</h2>
    <h3 v-if="sunset">{{get_word('sunset_time')}} {{sunset}}</h3>


    <ul v-if="hours && hours.length > 0 && show_apt">
        <li v-for="hour in hours" :key="hour" class="box" :class="{taken: (!hour.active || hour.occupied)}">
            <span v-text="hour.parsed_day"></span>|
            <h3 v-text="hour.parsed_date"></h3>
            <button :disabled="(!hour.active || hour.occupied)" class="btn" @click="handleOrder(hour)" v-html="(!hour.active || hour.occupied) ? get_word('taken') : get_word('order_now')"></button>
        </li>
    </ul>
    <div v-else-if="isFast">
      <h2 style="font-size: 28px;">{{get_word('isFast')}} </h2>
    </div>
    <div v-else>
       <h2 v-if="mikve.close_msg && mikve.close_msg  != ''">{{mikve.close_msg }} </h2>
       <h2 v-else>{{get_word('no_houres')}} </h2>
       <router-link to="/" class="btn gotoHomePage"> חזרה לדף הבית</router-link>
    </div>
   
  </div>
</template>

<script>
export default {
    data() {
        return {
            editID: this.isNull(this.$route.params.id) ? false: this.$route.params.id,
            hours: [],
            mikve: {},
            selectedHour: '',
            orderId: null,
            test: true,
            sunset: false,
            loaded: false,
            isFast: false
        }
    },
    mounted() {
      let type = this.$route.params.type.split("+")[0];

      let length = false;

      if(type != 1 && this.$route.params.type.split("+")[1] && this.$route.params.type.split("+")[1] != "") length = this.$route.params.type.split("+")[1];

      this.api({action: 'get_mikve_info',data: { mikve_id: this.$route.params.mikve_id } }, (data)=>{
        this.mikve = data.data;
          if(this.mikve.is_payments == "1")
          {
              if (!this.editID) this.$router.push('/order/' + this.$route.params.mikve_id + '/' +this.formattedDate(this.$route.params.date) +  '/' + this.$route.params.type+ '/999999' );
            else this.$router.push('/order/' + this.editID + '/' + this.$route.params.mikve_id + '/' + this.formattedDate(this.$route.params.date) +  '/' + this.$route.params.type+ '/999999');
          }
      });
     
      const date = new Date(this.$route.params.date);
       this.api({action: 'get_sunset',data:{ id: this.$route.params.mikve_id, date: date} }, (data)=>{
        this.sunset = data.data;
      });
      this.api({action: 'get_hours',data: { id: this.$route.params.mikve_id, date: date, type: type,length:length} }, (data)=>{
        if(data.data == 'fast')
        {
          this.isFast = true;
          this.hours = [];
        }
        else{
          this.hours = data.data;
        }

        this.$nextTick(()=>{
          this.loaded = true;
        });


      });
      
     
    },
    computed: {
        words() {
        return this.$store.state.words
        },
         show_apt() {
          let show = false;
          for(let i in this.hours)
          {
            if(this.hours[i].active)show = true;
          }
            return show;
        },
        date() {
            const date = new Date(this.$route.params.date);
            return new Intl.DateTimeFormat('he', {dateStyle: 'short'}).format(date);
        },
        title() {
            return this.mikve.city + ' - ' + this.mikve.address
        }
    },
    methods:{
      formattedDate(date) {
        const rawDate = new Date(date);
        const year = rawDate.getFullYear();
        const month = (rawDate.getMonth() + 1).toString().padStart(2, '0');
        const day = rawDate.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      handleOrder(date) {
        if (!this.editID) this.$router.push('/order/' + this.$route.params.mikve_id + '/' + date.date +  '/' + this.$route.params.type+'/'+date.room_id);
        else this.$router.push('/order/' + this.editID + '/' + this.$route.params.mikve_id + '/' + date.date +  '/' + this.$route.params.type+'/'+date.room_id);
        /*
        const res =  await this.$swal.fire({
          icon: 'question',
          text: this.get_word('set_appointment') + ' ' + this.date + ' ' + this.get_word('in_hour') + ' ' + hour.parsed_day +' '+  hour.parsed_date,
          showCloseButton: true,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: this.get_word('confirm'),
          cancelButtonText: this.get_word('cancel'),
        });
        if (res.isConfirmed) {
          this.api({ action: 'add_apt', data:{mikve_id: this.mikve.id, date: date,towel:true, type: this.$route.params.type}}, (data)=>{
            if (data.data) {
              this.$router.push('/order/' + data.data);
            }
          });
        }
        */
      }
    }
}
</script>

<style lang="scss">
  #selected_day_page {
    .gotoHomePage{margin: 0 auto;display: block;width: fit-content;line-height: 1;height: auto;padding: 10px 20px;font-weight: 700;font-size: 18px;}
    h1,h2,h3{text-align: center;}
    h3{padding: 10px 0;}
    h2{margin-bottom: 15px;}
    ul{ display: flex;flex-wrap: wrap;justify-content: center;gap: 15px;flex-direction: column;
      li{ display: flex;gap: 15px; align-items: center; justify-content: space-between; padding: 0px; padding-left: 0px; padding-right: 20px; 
        .btn { height: 50px; border-radius: var(--radius); font-size: 20px;}

        &.taken{ opacity: 0.7;
          span{text-decoration: line-through;font-weight: 500;}
          .btn { background: rgb(184, 184, 184); outline: none; }
        }
      }
    }
  }
</style>